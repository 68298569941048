import React from 'react'



const Courses = () => {
    return (
        <div>
            <div>

                <div className='bg-white py-6'>
                    <div className='px-8 md:px-20'>
                        <div className='text-[18px] pt-sans-bold pb-2'>Availbale Course in Astralia</div>
                        <div className=' flex justify-center'>
                            <input
                                className="bg-gray-100   text-black  rounded-md pt-sans-regular text-[11px] md:text-[14px] p-2 outline-none w-full "
                                type="text"
                                placeholder="Search a Course"
                            />
                        </div>

                    </div>


                </div>
                <div className='px-8 md:px-20 '>


                    <div className='flex flex-col md:flex-row md:justify-between items-center gap-4 md:gap-0/  py-4'>
                        <div className='bg-gray-600 rounded-full py-1 px-2'>
                            <div className="flex gap-4 text-white pt-sans-bold text-[13px]  items-center justify-center">
                                <span className='text-black  text-[11px]   bg-green-400 rounded-full px-2 py-1.5'>All Courses (4500)</span>
                                <span className='text-white text-[11px]  '>Bachelors (200)</span>
                                <span className='text-white text-[11px]  '>Masters (200)</span>
                                <span className='text-white text-[11px] '>Phd/Reserch (10)</span>
                            </div>

                        </div>


                        <div>
                            <div className='text-black text-[12px] pt-sans-bold  '>Sorted by:</div>
                            <div></div>

                        </div>



                    </div>
                    <div className='text-center pb-4'><span className='text-[20px] pt-sans-bold  '>Countries You Can Choose for Your higher Studies</span></div>
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className="bg-gray-100 shadow-md">
                            <div className="flex flex-col justify-between gap-4 px-4 py-4">
                                <div className="flex justify-between">
                                    <div>
                                        <div className='flex items-center gap-2'>
                                            <div className='pt-sans-bold text-xl'>Nursing</div><div className='pt-sans-bold text-[10px] bg-green-500 rounded-sm px-1'>Norvel Recommended</div>


                                        </div>
                                        <div className='pt-sans-regular text-[11px]'>
                                            Bachelors / 4 Years /
                                            <span className='pt-sans-bold'> 8.5 Lakhs per Year / </span>
                                            <span
                                                style={{
                                                    background: 'radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(235,112,35,1) 100%)',
                                                    WebkitBackgroundClip: 'text',
                                                    WebkitTextFillColor: 'transparent'
                                                }}
                                                className='text-[11px] pt-sans-bold'>
                                                IELTS Required
                                            </span>
                                        </div>


                                    </div>
                                    <div className='pt-4 hidden md:block'>
                                        <div>
                                            <div className='pt-sans-regular text-[10px]'>Living Expense:12Lakhs</div>
                                            <div className='text-[8px]'>Blocked Account</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="border-dotted-b   flex items-center justify-center"></div>
                                <div className='flex justify-between items-center block md:hidden'>
                                    <div className='pt-4 '>
                                        <div>
                                            <div className='pt-sans-regular text-[10px]'>Living Expense:12Lakhs</div>
                                            <div className='text-[8px]'>Blocked Account</div>
                                        </div>

                                    </div>
                                    <div>
                                        <div className="flex gap-1 ">
                                            {/* <div>Country Logo</div> */}
                                            <div className='pt-sans-bold text-[10px]'>United Kingdown</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="border-dotted-b   flex items-center justify-center blcok md:hidden"></div>

                                <div>
                                    <div className="flex  justify-between item-center">
                                        <div className='flex gap-4'>
                                            <div>logo</div>
                                            <div>
                                                <div className='text-[12px] pt-sans-bold '>University of central lancashire UCLAN</div>
                                                <div className='flex gap-2'>
                                                    <div className='text-[10px]'>Location : <span className='pt-sans-bold'>sydney</span></div>
                                                    <div className='text-[10px]'>Type : <span className='pt-sans-bold'>private</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex gap-1 hidden md:block">
                                                {/* <div>Country Logo</div> */}
                                                <div className='pt-sans-bold text-[10px]'>United Kingdown</div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="border-dotted-b   flex items-center justify-center"></div>
                                <div className="flex justify-between">
                                    <div className="flex gap-2 ">
                                        <div>
                                            <span className='inter-bold text-[10px] bg-green-100 px-2 rounded-full py-2'>2 years Stayback</span>
                                        </div>
                                        <div>
                                            <span className='inter-bold text-[10px] bg-green-100 px-2 rounded-full py-2'>100% Placement Options</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-2 py-6 items-center justify-center'>
                        <div className='text-black text-[12px] pt-sans-bold '>1 of 2 </div>
                        <div className='text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold '>NEXT </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Courses