import React from 'react'
import Header from '../components/header/Header'
import Center from '../components/center/Center'

import Footer from '../components/footer/Footer'
import UniversityDetailView from '../components/courses/UniversityDetailView'
import Courses from '../components/courses/Courses'

export const UniversityDetailsView = () => {
  return (
    <div>
    <div className='bg-gray-700 '>
      <Header />


      <Center />
      <UniversityDetailView/>
    </div>
    <div className='bg-gray-200'>
      <Courses/>

    </div>

    <div>
      <Footer />
    </div>





  </div>
  )
}
