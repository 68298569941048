import React from 'react'
import Header from '../components/header/Header'
import Center from '../components/center/Center'

import Footer from '../components/footer/Footer'
import Courses from '../components/courses/Courses'

const Countrycourse = () => {
  return (
    <div>
         <div className='bg-gray-700 '>
        <Header />


        <Center />
      </div>
      <div className='bg-gray-200'>
        <Courses/>

      </div>

      <div >
        <Footer />
      </div>



    </div>
  )
}

export default Countrycourse