import React,{useRef,useEffect} from 'react'
import styled from 'styled-components';
import Logout from '../authetication/Logout';


const CenterWrapper = styled.div`
  background-image: url('/Mobile.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  min-height: 26vh;  

  @media (min-width: 768px) {
  background-image: url('/web3.png');
    min-height: 60vh;  
  }
`;



const Center = ({ isDropdownVisible ,setDropdownVisible}) => {

  const dropdownRef = useRef(null);
  useEffect(() => {
    // Add click event listener to handle clicks outside of the dropdown
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);
  return (
    <CenterWrapper>
      <div className="px-4 md:px-0 py-10 md:py-0 ">
        <div className="px-2  md:px-0 md:py-0  ">

          {isDropdownVisible && (
            <div ref={dropdownRef}  className="absolute  top-0 right-4  md:right-8  px-2 md:px-6  bg-white border rounded shadow-lg">
              <div className=" py-1 md:py-2 cursor-pointer text-[13px] pt-sans-regular"><Logout /></div>
            </div>
          )}




        </div>




      </div>





    </CenterWrapper>
  )
}

export default Center