import React from 'react'
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

const Universities = () => {
    return (
        <div>
            <div>
                <div>

                    <div className='bg-white py-6'>
                        <div className='px-8 md:px-20'>
                            <div className='text-[18px] pt-sans-bold pb-2'>Availbale Course in Astralia</div>
                            <div className=' flex justify-center'>
                                <input
                                    className="bg-gray-100   text-black  rounded-md pt-sans-regular text-[11px] md:text-[14px] p-2 outline-none w-full "
                                    type="text"
                                    placeholder="Search a Course"
                                />
                            </div>

                        </div>


                    </div>
                    <div className='px-8 md:px-20 '>
                        <div className='flex flex-col md:flex-row md:justify-between items-center gap-4 md:gap-0/  py-4'>
                            <div className='bg-gray-600 rounded-full py-1 px-2'>
                                <div className="flex gap-4 text-white pt-sans-bold text-[13px]  items-center justify-center">
                                    <span className='text-black  text-[11px]   bg-green-400 rounded-full px-2 py-1.5'>All Courses (4500)</span>
                                    <span className='text-white text-[11px]  '>Bachelors (200)</span>
                                    <span className='text-white text-[11px]  '>Masters (200)</span>
                                    <span className='text-white text-[11px] '>Phd/Reserch (10)</span>
                                </div>

                            </div>


                            <div>
                                <div className='text-black text-[12px] pt-sans-bold  '>Sorted by:</div>
                                <div></div>

                            </div>



                        </div>
                        <div className='text-center pb-4'><span className='text-[20px] pt-sans-bold  '>Countries You Can Choose for Your higher Studies</span></div>


                        <div className="bg-gray-100 shadow-md">
                            <div className="flex flex-col justify-between gap-4 px-4 py-4">


                                <div>
                                    <div className="flex  justify-between item-center">
                                        <div className='flex gap-4'>
                                            <div>logo</div>
                                            <div>
                                                <div className='flex items-center gap-2'>
                                                    <div className='text-[13px] pt-sans-bold '>University of central lancashire UCLAN</div>
                                                    <div className='pt-sans-bold text-[10px] bg-green-500 rounded-sm px-1 whitespace-nowrap'>Norvel Recommended</div>

                                                </div>

                                                <div className='flex gap-2'>
                                                    <div className='text-[12px]'>Fees Starts at:<span className='text-[10px] pt-sans-bold '>20 Lakhs/Year / </span>  <span
                                                        style={{
                                                            background: 'radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(235,112,35,1) 100%)',
                                                            WebkitBackgroundClip: 'text',
                                                            WebkitTextFillColor: 'transparent'
                                                        }}
                                                        className='text-[11px] pt-sans-bold'>
                                                        IELTS Required
                                                    </span> </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden md:block'>
                                            <div className="flex gap-1 ">
                                                {/* <div>Country Logo</div> */}
                                                <div className='pt-sans-bold text-[10px]'>United Kingdown</div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="border-dotted-b   flex items-center justify-center"></div>
                                <div className='flex justify-between block md:hidden'>
                                    <div className='' >
                                        <div className='text-[12px]'>Location:</div>
                                        <div className='text-[12px] pt-sans-bold  '>Brookings,United States</div>
                                    </div>
                                    <div className=''>
                                        <div className="flex gap-1 ">
                                            {/* <div>Country Logo</div> */}
                                            <div className='pt-sans-bold text-[10px]'>United Kingdom</div>
                                        </div>
                                    </div>                  


                                </div>
                                <div class="border-dotted-b   flex items-center justify-center block md:hidden"></div>

                                <div className='flex justify-between'>
                                    <div className='hidden md:block' >
                                        <div className='text-[12px]'>Location:</div>
                                        <div className='text-[12px] pt-sans-bold  '>Brookings,United States</div>
                                    </div>

                                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
                                        <div className='px-6 flex gap-2 items-center'>
                                            <div>
                                                <ImportContactsIcon />

                                            </div>
                                            <div>
                                                <div className='text-[12px] pt-sans-regular'>Bachelor's</div>
                                                <div className='pt-sans-bold'>197</div>
                                            </div>
                                        </div>
                                        <div className='px-6 flex gap-2 items-center'>
                                            <div>
                                                <ImportContactsIcon />

                                            </div>
                                            <div>
                                                <div className='text-[12px] pt-sans-regular'>Bachelor's</div>
                                                <div className='pt-sans-bold'>197</div>
                                            </div>
                                        </div>
                                        <div className='px-6 flex gap-2 items-center'>
                                            <div>
                                                <ImportContactsIcon />

                                            </div>
                                            <div>
                                                <div className='text-[12px] pt-sans-regular'>Bachelor's</div>
                                                <div className='pt-sans-bold'>197</div>
                                            </div>
                                        </div>
                                        <div className='px-6 flex gap-2 items-center'>
                                            <div>
                                                <ImportContactsIcon />

                                            </div>
                                            <div>
                                                <div className='text-[12px] pt-sans-regular'>Bachelor's</div>
                                                <div className='pt-sans-bold'>197</div>
                                            </div>
                                        </div>
                                        <div className='px-6 flex gap-2 items-center'>
                                            <div>
                                                <ImportContactsIcon />

                                            </div>
                                            <div>
                                                <div className='text-[12px] pt-sans-regular'>Bachelor's</div>
                                                <div className='pt-sans-bold'>197</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="border-dotted-r   flex items-center justify-center"></div> */}
                                </div>
                            </div>
                        </div>

                        <div className='flex gap-2 py-6 items-center justify-center'>
                            <div className='text-black text-[12px] pt-sans-bold '>1 of 2 </div>
                            <div className='text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold '>NEXT </div>

                        </div>
                        {/* ________________________________________________________ */}

                        {/* <div className='flex justify-between gap-10 bg-white px-10'>
                            <div className='w-1/2'>
                                <div className='py-6'><span className='text-[14px] pt-sans-bold '>Description</span></div>
                                <div class="border-dotted-b   flex items-center justify-center"></div>
                                <div className='text-[12px] pt-sans-regular py-6 '>

                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore delectus distinctio quidem, alias molestias asperiores beatae molestiae, est pariatur magnam similique temporibus. Velit laudantium voluptatibus culpa quaerat iusto! At, amet.


                                </div>
                            </div>
                            <div className='w-1/2'>
                                <div className='py-6'><span className='text-[14px] pt-sans-bold '>Admission and Enquiry</span></div>
                                <div class="border-dotted-b   flex items-center justify-center"></div>
                                <div className='flex gap-4 py-6'>
                                    <div className='flex gap-2'>
                                        <div>
                                            logo
                                        </div>
                                        <div>
                                            <div className='text-[14px] pt-sans-bold'>Agency Name</div>
                                            <div className='text-[12px]'>Location : <span className='pt-sans-bold text-[12px] '>Kochi,Kerala</span></div>
                                        </div>
                                    </div>
                                    <div className='bg-green-400  pt-sans-regular rounded-full flex items-center px-4 text-[12px]'>
                                        Contact Agency
                                    </div>

                                </div>
                            </div>
                        </div> */}





                    </div>


                </div>
                {/* _________________________________________________________________________ */}






            </div>





        </div>
    )
}

export default Universities