// import React from 'react';

// const LoginPage = () => {
 
//   const clientId = '709840142800-8obh282muma9kptjq4q6ujlmor7s375k.apps.googleusercontent.com';
//   const redirectUri = 'https://norvel.site/account/api/v1/auth/google/callback/';

//   const handleLogin = () => {
//     const authUrl = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=email%20profile&service=lso&o2v=2&ddm=0&flowName=GeneralOAuthFlow`;
     
  
//     window.location.href = authUrl;
//   };

//   return (
//     <div>
//       <h1>Login</h1>
//       <button onClick={handleLogin}>Login with Google</button>
//     </div>
//   );
// };

// export default LoginPage;
import React from 'react';

const LoginPage = () => {
  const clientId = '709840142800-tbvoqu2hmc6vhm283ho61g7qtauorkhb.apps.googleusercontent.com';
  const redirectUri = 'https://norvel.in/auth/google/callback';

  const handleLogin = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=email%20profile&service=lso&o2v=2&ddm=0&flowName=GeneralOAuthFlow`;
    window.location.href = authUrl;
  };

  return (
    <div>
      <h1>Login</h1>
      <button onClick={handleLogin}>Login with Google</button>
    </div>
  );
};

export default LoginPage;



