import React from 'react'
import Universities from '../components/courses/Universities'
import Header from '../components/header/Header'
import Center from '../components/center/Center'

import Footer from '../components/footer/Footer'

const University = () => {
  return (
    <div>
         <div className='bg-gray-700 '>
        <Header />


        <Center />
      </div>
      <div className='bg-gray-200'>
        <Universities/>

      </div>

      <div>
        <Footer />
      </div>



    </div>
  )
}

export default University